import React from 'react'
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'reactstrap'
import { SocialIcon } from 'react-social-icons'
import Layout from '../components/layout'

const ContactPage = () => (
  <Layout className="h-100">
    <Container className="h-100" fluid>
      <Helmet title="contact" />
      <Row>
        <Col xs="12" className="text-center">
          <p>info@modo.sc</p>
          <p>
            modosc designs <br />
            po box 48
            <br />
            berkeley, ca 94701
          </p>
        </Col>
        <Col
          xs={12}
          sm={{ size: 8, offset: 2 }}
          className="d-flex flex-row justify-content-between"
        >
          <SocialIcon
            className="px-2"
            url="https://twitter.com/modoscdesigns"
          />
          <SocialIcon
            className="px-2"
            url="https://www.facebook.com/modoscdesigns"
          />
          <SocialIcon
            className="px-2"
            url="https://www.instagram.com/modoscdesigns/"
          />
          <SocialIcon
            className="px-2"
            url="https://soundcloud.com/modoscdesigns/"
          />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ContactPage
